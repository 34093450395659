import { useEffect, useState } from "react";
import { TezosToolkit } from '@taquito/taquito';
import { RpcClient, RpcClientCache } from '@taquito/rpc';
import { Tzip16Module } from '@taquito/tzip16';
import { ContractsLibrary } from "@taquito/contracts-library";
import {
    BrowserRouter,
    Switch,
    Route,
    Link
} from "react-router-dom";
import { NetworkType } from "@airgap/beacon-sdk";
import Admin from "./components/Admin";
import Home from "./components/Home";
import Artworks from "./components/Artworks";

import LoginOrDisconnect from "./components/LoginOrDisconnect";
import { BeaconWallet } from "@taquito/beacon-wallet";
import { UserAddressContext } from './engine/UserAddressContext';
import { AccountInfo } from "@airgap/beacon-sdk";
import FourOhFour from './components/FourOhFour';
import ContractOracle from './engine/endlessways-common-js/ContractOracle';
import Artifact from "./components/Artifact";
import './App.css';
import './Mobile.css';
import './Reactive.css';
import { Collection } from "./components/Collection";
import { ContractInfo } from './engine/endlessways-common-js/ContractOracle';
import Footer from "./components/Footer";
import Impressum from "./components/Impressum";
import Curation from "./components/Curation";
import About from "./components/About";
import { MailingListSignup } from "./components/MailingListSignup";
import Modal from 'react-modal';
import { ReactComponent as LogoMandelbrotGrossSVG } from "./assets/logoMandelbrotGross.svg";
import Exhibition from "./components/Exhibition";
//import IndexerOfflineWarningMaybe from "./components/IndexerOfflineWarningMaybe";
import TokenSeed from "./components/TokenSeed";


function App(props: {}) {

    Modal.setAppElement("#root");

    ////////////////
    ////////////////    

    
    // mainnet
    const indexerBaseUrl = "https://mainnet.indexer.endlessways.net";
    const baseImagesUri = "https://mainnet.images.endlessways.net/";
    const baseLiveUri = "https://mainnet.live.endlessways.net/";
    //const tezosRpcUrl = "https://mainnet-tezos.giganode.io";
    const tezosRpcUrl = "https://mainnet.api.tez.ie/";
    const tzktNetworkName = "mainnet";

    const preferredNetwork = NetworkType.MAINNET;
    const kContractInfos: ContractInfo[] = [{
        firstArtworkId: 1,
        contractAddress: "KT1VdCrmZsQfuYgbQsezAHT1pXvs6zKF8xHB",
        properties: {
            setSeedOnMint: true,
            separateScriptAndMintCount: true
        }
    }];
    const kContractRawDataPath = '/contractRawData-mainnet.json';

    /*
    // hangzhou
    const indexerBaseUrl = "https://greenroom.indexer.endlessways.net";
    //const indexerBaseUrl = "http://localhost:8082";
    const baseImagesUri = "https://greenroom.images.endlessways.net/";
    //const baseImagesUri = "http://localhost:8080/";
    const baseLiveUri = "https://greenroom.live.endlessways.net/";
    //const preferredNetwork = NetworkType.GHOSTNET;
    const preferredNetwork = NetworkType.CUSTOM;
    //const tezosRpcUrl = "https://hangzhounet.api.tez.ie/";
    const tezosRpcUrl = "https://rpc.ghostnet.teztnets.xyz/"
    //const tezosRpcUrl = "https://testnet-tezos.giganode.io";
    const tzktNetworkName = "ghostnet";

    const kContractInfos: ContractInfo[] = [{
        firstArtworkId: 1,
        contractAddress: "KT1AeicZFAgp6WdLiTYR1jQ6aLF3BCzu3REM",
        properties: {
            setSeedOnMint: true,
            separateScriptAndMintCount: true
        }
    }];
    const kContractRawDataPath = '/contractRawData-ghostnet.json';
    */

    // localhost
    /*
    // const preferredNetwork = NetworkType.CUSTOM;
    //const tezosRpcUrl = "http://localhost:20000";
    */
    ////////////////
    ////////////////

    const [contractIsSetup, setContractIsSetup] = useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [contractOracle, setContractOracle] = useState<ContractOracle | undefined>();
    useEffect(() => {
        (async () => {
            const contractsLibrary = new ContractsLibrary();
            try {
                if (kContractRawDataPath) {
                    await fetch(kContractRawDataPath).then((r) => r.json()).then((contractsLibraryInfo) => {
                        contractsLibrary.addContract(contractsLibraryInfo);
                    });
                }
            } catch (err) {
                console.warn("error initialising ContractsLibrary, falling back to slow method", err);
            } finally {
                const rpcClient = new RpcClient(tezosRpcUrl);
                const rpcClientCache = new RpcClientCache(rpcClient, 20000);
                const tz = new TezosToolkit(rpcClientCache);
                tz.addExtension(new Tzip16Module());
                tz.addExtension(contractsLibrary);
                const oracle = new ContractOracle(
                    {
                        tezosToolkit: tz, 
                        indexerBaseUrl: indexerBaseUrl,
                        baseImagesUri: baseImagesUri,
                        baseLiveUri: baseLiveUri,
                        tzktNetworkName: tzktNetworkName,
                    });
                setContractOracle(oracle);
            }
        })();
    }, []);

    useEffect(() => {
        if (!contractOracle) {
            return;
        }
        (async () => {
            await contractOracle.setupDirectContractAccess(kContractInfos);
            setContractIsSetup(true);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contractOracle]);

    var [beaconWallet, setBeaconWallet] = useState<BeaconWallet>();

    var [activeAccount, setActiveAccount] = useState<AccountInfo>();
    var [statefulUserAddress, setStatefulUserAddress] = useState<string>();


    useEffect(() => {
        async function populateActiveAccount() {
            if (beaconWallet) {
                setActiveAccount(await beaconWallet.client.getActiveAccount());
            } else {
                setActiveAccount(undefined);
            }
            //console.log("current beacon wallet: ", beaconWallet);
        }
        populateActiveAccount();
    }, [beaconWallet]);

    useEffect(() => {
        if (!contractOracle) {
            return;
        }
        async function populateUserAddress() {
            if (!contractOracle) {
                return;
            }
            try {
                if (activeAccount) {
                    const userAddress = activeAccount.address;
                    setStatefulUserAddress(userAddress);
                    //console.log("got user address " + userAddress);
                } else {
                    setStatefulUserAddress(undefined);
                }
            } catch (error) {
                console.error(error);
            }
        }
        populateUserAddress();
    }, [activeAccount, contractOracle, contractIsSetup]);

    //console.log(`rendering App.tsx, isAdmin is ${isAdmin}, beaconWallet`, beaconWallet);



    const mandelbrotLogoGrossStyle = 
        {
            position: 'fixed' as 'fixed',
            opacity: '20%',
            top: '75px',
            right: '75px',
            height: '130px',
            zIndex: '-1',
        }

    return (
        <UserAddressContext.Provider value={statefulUserAddress}>
            <BrowserRouter>

                <div className="site-container">
                    <Link className="site-root-link" to="/">
                        <img className="logo" src="/images/endlesswayslogo.svg" alt="endlessways logo" />
                    </Link>     
                    {contractOracle && (


                        <div>
                            <nav>
                                <ul>                                
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/artworks">Artworks</Link></li>
                                    <li><Link to="/exhibitions/1">Exhibitions</Link></li>
                                    <LoginOrDisconnect contractOracle={contractOracle} beaconWallet={beaconWallet} setBeaconWallet={setBeaconWallet} preferredNetwork={preferredNetwork} rpcUrl={tezosRpcUrl} />
                                </ul>
                            </nav>

                            {/* A <Switch> looks through its children <Route>s and
                            renders the first one that matches the current URL. */}
                            <Switch>
                                {beaconWallet &&
                                    <Route path="/admin">
                                        <Admin contractOracle={contractOracle} wallet={beaconWallet} />
                                    </Route>
                                }
                                <Route exact path="/">
                                    <Home contractOracle={contractOracle} />
                                </Route>
                                <Route path="/(live|token|mint|artifact)/:tokenId(\d+)">
                                    <Artifact contractOracle={contractOracle} wallet={beaconWallet} />
                                </Route>
                                <Route path="/artworks">
                                    <Artworks contractOracle={contractOracle} wallet={beaconWallet} forAdminPage={false} />
                                </Route>
                                <Route path="/collection/:tzAddress([a-zA-Z0-9]{36})">
                                    <Collection contractOracle={contractOracle} />
                                </Route>
                                <Route exact path="/impressum">
                                    <Impressum />
                                </Route>
                                <Route exact path="/curation">
                                    <Curation />
                                </Route>
                                <Route exact path="/about">
                                    <About />
                                </Route>
                                <Route path="/exhibitions/:exhibitionId(\d+)">
                                    <Exhibition contractOracle={contractOracle}/>
                                </Route>
                                <Route exact path="/newsletter">
                                    <MailingListSignup />
                                </Route>
                                <Route exact path="/tokenSeed/:tokenId(\d+)">
                                    <TokenSeed contractOracle={contractOracle}/>
                                </Route>
                                <Route >
                                    <FourOhFour />
                                </Route>
                            </Switch>
                        </div>
                    )}
                    <LogoMandelbrotGrossSVG style={mandelbrotLogoGrossStyle} />
                </div >

            <Footer />
                
            </BrowserRouter>
        </UserAddressContext.Provider>
    )
}

export default App;