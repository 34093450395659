import { BeaconWallet } from "@taquito/beacon-wallet";
import { FunctionComponent, useEffect, useState } from "react";
import { Prompt, useParams } from "react-router";
import { BeaconError } from "@airgap/beacon-sdk";
import React from "react";
import {
    Link
} from "react-router-dom";
import ContractOracle from '../engine/endlessways-common-js/ContractOracle';
import ArtworkInfo, { RoyaltyInfo } from '../engine/endlessways-common-js/ArtworkInfo';
import { AddressValidationError, MichelsonMap } from '@taquito/michelson-encoder';
import { ArtworkScriptType, MintHtmlGenerator } from '../engine/endlessways-common-js/MintHtmlGenerator';
import BigNumber from 'bignumber.js';
import Frame from 'react-frame-component';
import { validateAddress, ValidationResult } from "@taquito/utils";
import { useUserAddressContext } from "../engine/UserAddressContext";
import FourOhFour from './FourOhFour';
import { ReactComponent as ReloadSVG } from '../assets/reload.svg';
import useTraceUpdate from '../engine/TraceUpdate';
import ErrorBoundary from "./ErrorBoundary";
import { ContractMethod, TransferParams, Wallet, WalletContract, WalletTransferParams } from '@taquito/taquito';
var cloneDeep = require('lodash.clonedeep');
const axios = require('axios');

type EditArtworkProps = {
    contractOracle: ContractOracle,
    wallet?: BeaconWallet,
}

const ONE_MILLION = 1000000;

function EditArtwork(props: React.PropsWithChildren<EditArtworkProps>) {
    useTraceUpdate("EditArtwork", props);

    let { artworkIdAsString } = useParams<{ artworkIdAsString: string }>();
    let artworkId = parseInt(artworkIdAsString);

    const [artworkInfo, setArtworkInfo] = useState<ArtworkInfo>();
    const [isStoring, setIsStoring] = useState<boolean>(false);
    const [isRerolling, setIsRerolling] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [rerollError, setRerollError] = useState<string>();
    const [warning, setWarning] = useState<string>();
    const [localValues, setLocalValues] = useState<Map<string, any>>(new Map());
    const [localValuesDifferFromStorage, setLocalValuesDifferFromStorage] = useState<boolean>(false);

    const [artworkScriptPreviewIsVisible, setArtworkScriptPreviewIsVisible] = useState<boolean>(false);
    const [previewTokenSeed, setPreviewTokenSeed] = useState(generateTokenSeed());
    const [previewMintNumber, setPreviewMintNumber] = useState(new BigNumber(1));
    const [localValueScript, setLocalValueScript] = useState<string>();

    const [userCanEdit, setUserCanEdit] = useState<boolean>();
    const [userIsAdmin, setUserIsAdmin] = useState<boolean>();
    const [userCanView, setUserCanView] = useState<Boolean>(false);

    let userAddress = useUserAddressContext();

    type EditableArtworkProperty = {
        storageKey: string,
        type: string,
        defaultValue?: any,
        description?: string,
        placeholderValue?: any
    }
    const kEditableArtworkProperties: EditableArtworkProperty[] = [
            { storageKey: "artist", type: "text", description:  "Artist display name" },
            { storageKey: "artistTwitter", type: "text", description: "Optional: artist twitter", placeholderValue: "eg https://twitter.com/<twitter id>" },
            { storageKey: "artistExtraLink", type: "text", description: "Optional: extra artist link (eg homepage, linktree, etc.)", placeholderValue: "eg https://..." },
            { storageKey: "title", type: "text", description: "Artwork title" },
            { storageKey: "description", type: "textarea", description: "Description" },
            { storageKey: "rights", type: "text", defaultValue: "CC BY-NC 4.0", description: "Rights statement. Briefly inform token holders what they are allowed to do with the output of your code." },
            { storageKey: "is_paused", type: "checkbox", description:  "Paused/Disabled. If checked, only artist can mint." },
            { storageKey: "isVisible", type: "checkbox", description: "Visible to everybody. If unchecked, only artist and admin can see this artwork." },
            { storageKey: "price_to_mint", type: "number", description: "Price to mint in ꜩ" },
            { storageKey: "max_mint_count", type: "number", description: "Maximum number of mints (you can set this to any number up to 10,000,000,000)" },
            { storageKey: "selectSeedOnMint", type: "checkbox", description: "Buyer may preview different seeds and select the one they want to mint." },
            { storageKey: "primary_royalties", type: "primary-royalties", description: "Primary royalties (after Endless Ways cut)" },
            { storageKey: "secondaryRoyalties", type: "secondary-royalties", description: "Secondary marketplace royalties" },
            /*{ storageKey: "rerolls_enabled", type: "checkbox", description: "Enable re-rolls. If checked, token owners can re-roll the mint seed after minting." },
            { storageKey: "price_to_reroll", type: "number", description: "If re-rolls are enabled, this is the cost to re-roll in ꜩ. Can be 0." },*/
            { storageKey: "script", type: "textarea", description: "Source code" },
            { storageKey: "scriptTypeString", type: "dropdown"},
            { storageKey: "waitTimeMillis", type: "number", description: "Time to wait before grabbing screenshot of script, ms (default 1000)" },
            { storageKey: "aspectRatio", type: "number", description: "Aspect ratio, expressed as a float. Eg for 3:2 this field should be 1.5, for 16:9 it should be 1.77777778, etc." },
            { storageKey: "archivist", type: "text", description: "Archivist wallet address. The Archivist has full write access to this artwork. Leave empty for no archivist.", placeholderValue: "tz1..." },
            { storageKey: "baseImagesUri", type: "text", description: "Base URI for images in metadata JSON, leave blank unless you know what you are doing"},
            { storageKey: "baseLiveUri", type: "text", description: "Base URI for live HTML view in metadata JSON, leave blank unless you know what you are doing"},
        ];
    


    const kInfoKeys = ["artist", "artistTwitter", "artistExtraLink", "title", "description", "rights", "is_paused", "isVisible"];
    const kPricingKeys = ["selectSeedOnMint", "max_mint_count", "price_to_mint", "primary_royalties", "secondaryRoyalties"];
    const kScriptExtraKeys = ["waitTimeMillis", "aspectRatio"];
    const kMiscKeys = ["archivist", "baseImagesUri", "baseLiveUri"];

    // shortcuts that just call set_artwork_extra_data on the contract
    const kExtraDataKeys = ["waitTimeMillis", "scriptTypeString", "isVisible", "selectSeedOnMint", "artistTwitter", "artistExtraLink", "aspectRatio", "baseImagesUri", "baseLiveUri"];
    const kTokenMetadataKeys = ["rights"];


    useEffect(() => {
        const details = (artworkInfo ? ` - ${(artworkInfo.title + " ") ?? " "}${artworkInfo.artist ?? ""}` : "");
        document.title = `Endless Ways: editing artwork ${artworkId}${details}`;
    }, [artworkId, artworkInfo, userAddress, userCanEdit]);

    async function updateStorageValues() {
        /*if (props.contractOracle.contractDirectAccess.length == 0) {
            console.log("no cda, bailing out")
            return 
        }*/

        const forceRefresh = true;
        var artworkInfo = await props.contractOracle.getArtworkFromContract(artworkId, forceRefresh);
        //var artworkInfo = await props.contractOracle.getArtwork(parseInt(artworkId), {forceRefresh: true});
        if (artworkInfo) {
            //console.log("got storage values:", artworkInfo);
            setArtworkInfo(artworkInfo);
        } else {
            //console.log(`couldn't get storage values for artwork with id ${artworkId}`)
        }
    }
    //console.log("in EditArtworks with props", props);
    useEffect(() => {
        updateStorageValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contractOracle.contractDirectAccess, artworkId]);

    useEffect(() => {
        (async () => {
            if (artworkInfo && userAddress) {
                const newCanEdit = (userAddress === artworkInfo.artist_address) || (userAddress === artworkInfo.archivist);
                setUserCanEdit(userAddress === artworkInfo.artist_address || userAddress === artworkInfo.archivist);
                const newUserIsAdmin = (await props.contractOracle.getAdminWalletAddress()) === userAddress;
                setUserIsAdmin(newUserIsAdmin);
                setUserCanView(newCanEdit || newUserIsAdmin);
            } else {
                setUserCanEdit(false);
            }
        })()
    }, [userAddress, artworkInfo, props.contractOracle]);

    function convertRoyaltiesToTzip21Json(royalties: RoyaltyInfo): string {
        interface Shares {
            [key: string]: number 
        }
        var shares: Shares = {};
        royalties.shares.forEach((share) => {
            shares[share[0]] = share[1].toNumber();
        })
        var result = {
            decimals: royalties.decimals.toNumber(),
            shares: shares
        }
        //console.log("from", royalties.shares, "produced", shares, "in", result);
        return JSON.stringify(result);
    }

    function validatePriceToMint(): boolean {
        const priceToMintTezString = localValues.get("price_to_mint") as string;
        if (!priceToMintTezString) {
            return true;
        }
        const priceTez = parseFloat(priceToMintTezString);
        const priceMutez = Math.round(priceTez * ONE_MILLION);
        if (priceMutez <= 0) {
            setError("Price to mint must be >= 0.000001");
            return false;
        }
        return true;
    }

    function validateScriptSize(): boolean {
        const script = localValues.get("script") as string;
        if (!script) {
            return true;
        }
        const scriptHex = ContractOracle.utf8StringToHex(script);
        const scriptLengthBytes = scriptHex.length/2;
        //console.log("script is ", scriptLengthBytes, "bytes long");
        // 32512 bytes
        // actual max seems a touch higher than this, but this is fine i think
        const kMaxScriptLengthBytes = 127*256; 
        if (scriptLengthBytes > kMaxScriptLengthBytes) {
            setError("Artwork script is too long - must be <= " + kMaxScriptLengthBytes + " bytes");
            return false;
        }
        return true;
    }

    function validateWalletAddress(address: string, problemLocation: string): boolean {
        const addressValidationResult = validateAddress(address);
        if (addressValidationResult !== ValidationResult.VALID) {
            setError(`'${address}' is not a valid Tezos address - check ${problemLocation}`);
            return false;
        }
        return true;
    }

    function validateArchivistAddress(): boolean {
        const archivistAddress = localValues.get("archivist") as string;
        if (!archivistAddress || (archivistAddress.length === 0)) {
            return true;
        }
        return validateWalletAddress(archivistAddress, "archivist address");
    }

    function validateAristLinks() {
        const artistTwitter = localValues.get("artistTwitter") as string;
        if (artistTwitter && !validateLink(artistTwitter, "artist twitter link")) {
            return false;
        };
        const artistExtraLink = localValues.get("artistExtraLink") as string;
        if (artistExtraLink && !validateLink(artistExtraLink, "artist extra link")) {
            return false;
        }
        return true;
    }

    function validateLink(link: string, location: string): boolean {
        console.log('validating', location, link);
        if (!link.startsWith("https://")) {
            setError("URL must start with https:// - check " + location);
            return false;
        }
        return true;
    }

    function validateRoyalties(): boolean {
        function getCombinedShares(royaltyInfo: RoyaltyInfo): Array<number> {
            const sharesCombined = royaltyInfo.shares.reduce((total, share) => {
                return { "0": "none", "1": total[1].plus(share[1]) }
            })
            const maxSum = Math.pow(10, royaltyInfo.decimals.toNumber());

            return [maxSum, sharesCombined[1].toNumber()];
        }

        // check primary royalties sum to 100
        const primaryRoyalties = localValues.get("primary_royalties") as RoyaltyInfo;
        if (primaryRoyalties) {
            if (primaryRoyalties.shares.length === 0) {
                setError("No primary royalties set - there must be at least one.")
                return false;
            }

            for (var i = 0; i < primaryRoyalties.shares.length; i++) {
                const address = primaryRoyalties.shares[i][0];
                console.log('validating primary royalties address', address);
                if (!validateWalletAddress(address, "primary royalties")) {
                    return false;
                }
            }


            const [expectedSum, shareSum] = getCombinedShares(primaryRoyalties);
            //console.log("shareSum", shareSum, "expected", expectedSum);
            if (shareSum !== expectedSum) {
                const shareSumPercent = (shareSum / expectedSum) * 100;
                setError("Primary royalties add up to " + shareSumPercent + "% - this needs to be 100%.");
                return false;
            }
        }

        const secondaryRoyalties = localValues.get("secondaryRoyalties") as RoyaltyInfo;
        if (secondaryRoyalties) {
            for (var shareIndex = 0; shareIndex < secondaryRoyalties.shares.length; shareIndex++) {
                const address = secondaryRoyalties.shares[shareIndex][0];
                if (!validateWalletAddress(address, "secondary royalties")) {
                    return false;
                }
            }

            if (secondaryRoyalties.shares.length > 0) {
                const [expectedSum, shareSum] = getCombinedShares(secondaryRoyalties);
                if (shareSum > expectedSum) {
                    const shareSumPercent = (shareSum / expectedSum) * 100;
                    setError("Secondary royalties add up to " + shareSumPercent + "% - this needs to be <=100%.");
                    return false;
                }
                if (shareSum > (expectedSum / 2)) {
                    setWarning("Secondary royalties sum to >50% - this seems very high, are you sure?")
                }
            }
        }

        return true;
    }

    async function storeAllValues(): Promise<void> {
        setWarning(undefined);
        setError(undefined);
        if (!validatePriceToMint()) {
            return;
        }
        if (!validateRoyalties()) {
            return;
        }
        if (!validateScriptSize()) {
            return;
        }
        if (!validateArchivistAddress()) {
            return;
        }
        if (!validateAristLinks()) {
            return;
        }
        const cda = await props.contractOracle.getContractDirectAccess(artworkId);
        const contract = cda?.contract;
        if (!contract) {
            setError("no contract loaded");
            return;
        }

        setIsStoring(true);

        function getContractMethodForArtworkProperty(key: string, contract: WalletContract): ((...args: any[]) => ContractMethod<Wallet>) {
            switch(key) {
                case "artist": return contract.methods.set_artwork_artist;
                case "title": return contract.methods.set_artwork_title;
                case "description": return contract.methods.set_artwork_description;
                case "is_paused": return contract.methods.set_artwork_paused;
                case "max_mint_count": return contract.methods.set_artwork_max_mint_count;
                case "price_to_mint": return contract.methods.set_artwork_mint_price;
                case "primary_royalties": return contract.methods.set_artwork_primary_royalties;
                case "secondaryRoyalties": return contract.methods.set_artwork_secondary_royalties;
                    /*{ storageKey: "rerolls_enabled": return contract.methods.set_artwork_rerolls_enabled;
                case "price_to_reroll": return contract.methods.set_artwork_rerolls_enabled;*/
                case "script": return contract.methods.set_artwork_script;
                case "archivist": return contract.methods.set_archivist;
                default: throw new Error("No contract method for '" + key + "'");
            }
        }
    
        try {
            var transactions: TransferParams[] = [];
            const extraDataMapUpdate = new MichelsonMap();
            const tokenMetadataMapUpdate = new MichelsonMap();
            for (let i = 0; i < kEditableArtworkProperties.length; i++) {
                const dsp = kEditableArtworkProperties[i];
                var value = localValues.get(dsp.storageKey);
                if (value !== undefined) {
                    //console.log("batch contract call: updating", dsp.storageKey, "to", value);

                    // hand re-rolls as a pair
                    // only process one of the pair
                    if (kExtraDataKeys.indexOf(dsp.storageKey) !== -1) {
                        // handle the 'extra_data' map differently
                        const metadataValue = (dsp.storageKey === "selectSeedOnMint") ? (value ? "1" : "0") : value.toString();
                        if ((dsp.storageKey !== "baseImagesUri" && dsp.storageKey !== "baseLiveUri") || metadataValue.length > 0) {
                            extraDataMapUpdate.set(dsp.storageKey, ContractOracle.utf8StringToHex(metadataValue)); 
                        }
                    } else if (kTokenMetadataKeys.indexOf(dsp.storageKey) !== -1) {
                        // handle the 'token_metadata' map differently
                        tokenMetadataMapUpdate.set(dsp.storageKey, ContractOracle.utf8StringToHex(value.toString()));
                    } else {
                        const contractMethod = getContractMethodForArtworkProperty(dsp.storageKey, contract);

                        // convert strings to hex
                        if (((dsp.type === "text") && (dsp.storageKey !== "archivist")) || (dsp.type === "textarea") || (dsp.type === "dropdown")) {
                            value = ContractOracle.utf8StringToHex(value);
                        }
                        
                        const isRerollKey = (dsp.storageKey === "rerolls_enabled") || (dsp.storageKey === "price_to_reroll");
                        if (isRerollKey) {
                            const hasBothRerollsKeys = localValues.has("price_to_reroll") && localValues.has("rerolls_enabled");
                            if (dsp.storageKey === "rerolls_enabled" && hasBothRerollsKeys) {
                                // only process one of them, so ignore this one
                            } else {
                                // process this one
                                var priceToRerollMutez = artworkInfo?.price_to_reroll ?? 0;
                                const newPriceToRerollTez = localValues.get("price_to_reroll");
                                if (newPriceToRerollTez) {
                                    priceToRerollMutez = Math.round(newPriceToRerollTez * ONE_MILLION);
                                }
                                const rerollsEnabled = localValues.get("rerolls_enabled") ?? artworkInfo?.rerolls_enabled ?? false;
                                //console.log("updating re-rolls with enabled", rerollsEnabled, "and price in mutez", priceToRerollMutez);
                                transactions.push(contractMethod(artworkId, rerollsEnabled, priceToRerollMutez).toTransferParams());
                            }
                        } else if (dsp.storageKey === "primary_royalties") {
                            transactions.push(contractMethod(artworkId, value.decimals, value.shares).toTransferParams());
                        } else if (dsp.storageKey === "secondaryRoyalties") {
                            let royaltiesJson = convertRoyaltiesToTzip21Json(value);
                            //console.log("secondary royalties setting", royaltiesJson);
                            transactions.push(contractMethod(artworkId, ContractOracle.utf8StringToHex(royaltiesJson)).toTransferParams());
                        } else if (dsp.storageKey === "artist") {
                            const artistAddress = artworkInfo!.artist_address
                            const artistStringUtf8 = ContractOracle.hexToUtf8String(value);
                            transactions.push(contractMethod(artworkId,
                                ContractOracle.utf8StringToHex(artistStringUtf8),
                                ContractOracle.utf8StringToHex(`["${artistAddress}"]`)
                            ).toTransferParams());
                        } else if (dsp.storageKey === "price_to_mint") {
                            const priceTez = parseFloat(value);
                            const priceMutez = Math.round(priceTez * ONE_MILLION);
                            transactions.push(contractMethod(artworkId, priceMutez).toTransferParams());
                        } else if (dsp.storageKey === "script") {
                            //increase storage limit
                            const scriptSizeHex = (value as string).length;
                            const scriptSizeBytes = scriptSizeHex/2;
                            const storageLimit = Math.ceil(scriptSizeBytes * 1.2);
                            //console.log("script is going to take", scriptSizeBytes, "bytes -> setting storage limit to", storageLimit);
                            transactions.push({...contractMethod(artworkId, value).toTransferParams(), storageLimit: storageLimit});
                        } else if (dsp.storageKey === "archivist") {
                            if ((value as string).length === 0) {
                                value = undefined;
                            }
                            transactions.push(contractMethod(artworkId, value).toTransferParams());
                        } else {
                            transactions.push(contractMethod(artworkId, value).toTransferParams());
                        }
                    }
                }
            }
            if (extraDataMapUpdate.size > 0) {
                //console.log("writing metadata", metadataMapUpdate);
                const extraDataUpdateMethod = contract.methods.update_artwork_extra_data;
                transactions.push(extraDataUpdateMethod(artworkId, extraDataMapUpdate).toTransferParams());
            }

            if (tokenMetadataMapUpdate.size > 0) {
                const tokenMetadataUpdateMethod = contract.methods.update_artwork_token_metadata;
                transactions.push(tokenMetadataUpdateMethod(artworkId, tokenMetadataMapUpdate).toTransferParams());
            }


            //console.log("going to get estimates...");

            /* with script size = 8333 bytes, 
            https://hangzhou2net.tzkt.io/onpdEPptvPvG441XCAhJGCzt82ohnuttDRBED9egf5z2cM4XFY9
            estimates were: 
            {
                "_milligasLimit": 5559097,
                "_storageLimit": 8333,
                "opSize": 8455,
                "minimalFeePerStorageByteMutez": 250,
                "baseFeeMutez": 100
            }
            .. with storageLimit set to 12499, 8333 ended up being used. 
            */


            //props.contractOracle.tezosToolkit.setSignerProvider(await InMemorySigner.fromSecretKey('edsk2kf1gh7fwbJgbYSQ8dJfMe4Z8GSZqZRFHAHGb33t3Nt5a4BkGj'));
            //var estimateBatch = await props.contractOracle.tezosToolkit.estimate.batch(transactions.map(makeParamsWithKind));
            //console.log( "estimatesBatch[0].storageLimit", estimateBatch[0].storageLimit, "other estimates:", estimateBatch);

            //const 
            const walletBatch = props.contractOracle.tezosToolkit.wallet.batch();
            transactions.forEach((t, index) => {
                var walletParams: WalletTransferParams = {...t, 
                    /*fee: Math.floor(estimateBatch[index].suggestedFeeMutez * 1.333),
                    gasLimit: estimateBatch[index].gasLimit, 
                storageLimit: Math.floor(estimateBatch[index].storageLimit * 1.5)*/
            };
                walletBatch.withTransfer(walletParams);
            })
            console.log("walletBatch is", walletBatch);
            const batchOp = await walletBatch.send();
            await batchOp.confirmation(1);


            await updateStorageValues();
            // i'm sorry i know this sucks
            setLocalValues(new Map());
            setLocalValuesDifferFromStorage(false);
        } catch (error: any) {
            console.log(error);
            if (error instanceof BeaconError) {
                let beaconError = error as BeaconError;
                setError(beaconError.fullDescription.description);
            } else if (error instanceof AddressValidationError) {
                setError("'" + error.value + "' is not a valid Tezos address");
            } else if (error instanceof Error) {
                setError((error as Error).message);
            } else {
                setError("Error doing transaction, check log for more details");
            }
            await updateStorageValues();
        } finally {
            setIsStoring(false);
        }
    }

    //console.log("EditArtwork rendering with beacon wallet:");
    //console.log(props.wallet);

    function getLocalOrArtworkValue(key: string): string | undefined {
        const localValue = localValues.get(key);
        if (localValue) {
            //console.log("found local value", localValue, "for", key);
            return localValue;
        }
        return getArtworkValueForKey(key);
    }
    const artworkScriptTypeString = getLocalOrArtworkValue("scriptTypeString") ?? "p5.js";
    const artworkScriptType = MintHtmlGenerator.getScriptTypeForString(artworkScriptTypeString);

    // script update handling. we want to delay the script handling by kScriptUpdateDelayMs
    const kScriptUpdateDelayMs = 1000;
    const [previewScript, setPreviewScript] = useState<string>("");
    const [previewScriptTimeoutId, setPreviewScriptTimeoutId] = useState<number>();
    useEffect(() => {
        //console.log('useEffect triggererd');

        /*
        if (localValueScript) {
            const scriptHex = ContractOracle.utf8StringToHex(localValueScript);
            console.log("script has hex:", scriptHex);
            console.log("which converts back to:", ContractOracle.hexToUtf8String(scriptHex));
        }*/

        const script = (localValueScript ?? artworkInfo?.script) ?? "";
        if (previewScriptTimeoutId) {
            clearTimeout(previewScriptTimeoutId);
        }
        const handler: TimerHandler = () => {
            setPreviewScript(script);
        };
        const timeoutId = setTimeout(handler, kScriptUpdateDelayMs);
        setPreviewScriptTimeoutId(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localValueScript, artworkInfo?.script]);


    // i know this is gross i'm sorry
    function valueChanged(storageKey: string, newValue: any, rawEvent?: React.ChangeEvent<HTMLInputElement>) {
        //console.log(storageKey + " changed to " + newValue);
        //console.log(rawEvent);

        // eslint-disable-next-line eqeqeq
        if (!artworkInfo || (getArtworkValueForKey(storageKey) == newValue)) {
            //console.log("deleting local value for", storageKey);
            localValues.delete(storageKey);
        } else {
            //const oldValue = getArtworkValueForKey(storageKey);
            //console.log("setting local value for", storageKey, "from", oldValue, "to", newValue );
            localValues.set(storageKey, newValue);
        }
        if (storageKey === "script") {
            setLocalValueScript(newValue);
        }
        //console.log("edited local values: ", localValues);
        setLocalValuesDifferFromStorage(localValues.size !== 0);
    }

    //console.log("rendering with local values:");
    //console.log(localValues);

    function getArtworkValueForKey(key: string): any | undefined {
        if (!artworkInfo) {
            return undefined;
        }
        let artworkValue = Reflect.get(artworkInfo as any, key);
        if (typeof(artworkValue) !== "undefined") {
            return artworkValue;
        }
        let hexValue = artworkInfo?.extra_data.get(key) ?? artworkInfo?.token_metadata.get(key);
        if (hexValue) {
            return ContractOracle.hexToUtf8String(hexValue);
        }
        return undefined;
    }

    function getDisplayArtworkValueForKey(key: string): string | undefined {
        let rawValue = getArtworkValueForKey(key);
        if (!rawValue) {
            return undefined;
        }
        //console.log("tostringing value for key",key,":",rawValue);
        if (key === "price_to_mint" || key === "price_to_reroll") {
            let mutezValue = parseInt(rawValue);
            let tezValue = mutezValue / ONE_MILLION;
            return tezValue.toString();
        } else {
            return rawValue?.toString()
        }

    }

    function makeNewSeed() {
        console.log("make new seed");
        setPreviewTokenSeed(generateTokenSeed());
        /*const maxMintCount = artworkInfo?.max_mint_count ?? new BigNumber(1000);
        setPreviewMintNumber(new BigNumber(maxMintCount.multipliedBy(Math.random()).toFixed(0, BigNumber.ROUND_FLOOR)));*/
        setPreviewMintNumber(previewMintNumber.plus(1));
    }

    function generateTokenSeed(): string {
        var newSeed = "";
        for (var i = 0; i < 32; i++) {
            const byte = Math.floor(255.99999999 * Math.random());
            var zerofilled = ('0' + byte.toString(16)).slice(-2)
            newSeed += zerofilled;
        }
        return newSeed;
    }

    function artworkScriptPreview(scriptType: ArtworkScriptType, tokenSeed: string, mintNumber: BigNumber, script: string) {
        if (!artworkInfo) {
            return <></>
        }
        const startsWithHtml = script.trim().startsWith("<html");
        if (scriptType === ArtworkScriptType.CUSTOM && !startsWithHtml) {
            return <div className="error">Invalid custom page content: must start with {`<html>`}.</div>
        } else if (scriptType !== ArtworkScriptType.CUSTOM && startsWithHtml) {
            return <div className="error">Invalid {MintHtmlGenerator.getStringForScriptType(scriptType)} content: must not start with {`<html>`}.</div>
        }
        try {
            var pageContent = MintHtmlGenerator.getMintHtml(scriptType, artworkInfo.id, script, tokenSeed, mintNumber);
            //console.log("preview frame content:", pageContent)
            return (
                <div className="artwork-script-preview" style={{ aspectRatio: `${artworkInfo.aspectRatio}` }}>
                    <button className="reload-button" onClick={e => { makeNewSeed() }} >
                        <ReloadSVG style={{ width: "17px" }} />
                    </button>
                    <ErrorBoundary>
                        <Frame style={{ display: "block", width: "100%", aspectRatio: `${artworkInfo.aspectRatio}` }} initialContent={pageContent} frameBorder={0}>
                        </Frame>
                    </ErrorBoundary>
                </div>
            )
        } catch (error: any) {
            return (
                <div className="artwork-script-preview">
                    <div className="error">{error.toString()}</div>
                </div>
            )
        }
    }

    function viewArtworkLink() {
        return (
            <div className="view-artwork-link">
                <Link to={`/artworks/${artworkId}`}><i className="fas fa-arrow-left"></i>&nbsp; View artwork</Link>
            </div>
        );
    }

    function storeButton() {
        return (
            <div className="store-button">
                <button
                    className="button"
                    disabled={(!localValuesDifferFromStorage) || isStoring}
                    onClick={e => { storeAllValues() }}
                >
                    {isStoring ? (
                        <span>
                            <i className="fas fa-spinner fa-spin"></i>&nbsp; storing, please wait...
                        </span>
                    ) : (
                        <span>
                            <i className="far fa-save"></i>&nbsp; store
                        </span>
                    )}
                </button>
                {error && <div className="store-error">{error}</div>}
                {warning && <div className="store-warning">{warning}</div>}
                {viewArtworkLink()}
            </div>)
    }

    async function rerollMint1() {
        if (!artworkInfo) {
            return;
        }
        const contract = (await props.contractOracle.getContractDirectAccess(artworkId))?.contract;
        if (!contract) {
            return;
        }

        try {
            setRerollError(undefined);
            setIsRerolling(true);
            const mint0TokenId = ContractOracle.getTokenIdForMint(artworkInfo.id, new BigNumber(1));
            console.log(mint0TokenId);
            const op = await contract.methods.reroll_seed(mint0TokenId).send({ amount: artworkInfo.price_to_reroll, mutez: true });
            await op.confirmation();
        } catch (error) {
            console.error(error);
            if (error instanceof Error) {
                setRerollError((error as Error).message);
            } else {
                setRerollError("Error doing transaction, check log for more details");
            }
        } finally {
            setIsRerolling(false);
        }
    }


    function rerollMint1Button() {

        if (!artworkInfo) {
            return <></>
        }

        const canRerollMint0 = (artworkInfo.mint_count.isGreaterThan(0)) &&
            (artworkInfo.artist_address === userAddress);

        return (
            <div className="extra-button">
                <button
                    className="button"
                    disabled={!canRerollMint0}
                    onClick={e => { rerollMint1() }}
                >
                    {isRerolling ? (
                        <span>
                            <i className="fas fa-spinner fa-spin"></i>&nbsp; rerolling...
                        </span>
                    ) : (
                        <span>
                            <i className="fa fa-random"></i>&nbsp; reroll mint #1
                        </span>
                    )}
                </button>
                {rerollError && <div className="error">{error}</div>}
            </div>)

    }

    const [primaryRoyalties, setPrimaryRoyalties] = useState<RoyaltyInfo>();
    const [secondaryRoyalties, setSecondaryRoyalties] = useState<RoyaltyInfo>(localValues.get("secondaryRoyalties") ?? (artworkInfo ? getArtworkValueForKey("secondaryRoyalties") : undefined));
    useEffect(() => {
        if (artworkInfo) {
            setPrimaryRoyalties(localValues.get("primary_royalties") ?? getArtworkValueForKey("primary_royalties"));
            setSecondaryRoyalties(localValues.get("secondaryRoyalties") ?? getArtworkValueForKey("secondaryRoyalties"));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artworkInfo, localValues]);
    useEffect(() => {
        valueChanged("primary_royalties", primaryRoyalties);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [primaryRoyalties]);
    useEffect(() => {
        valueChanged("secondaryRoyalties", secondaryRoyalties);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondaryRoyalties]);

    var body = <div className="fade-in-out-2s">Loading data from contract...</div>

    //console.log("evaluating with artworkScriptType=", artworkScriptType);

    //console.log("userCanEdit:", userCanEdit);
    //console.log("user address", userAddress);

    if (artworkInfo) {
        if (userCanView !== undefined && !userCanView) {
            return <FourOhFour />
        }

        const localValueScript = localValues.get("script");
        const hasNoScript = ((localValueScript === undefined) ?
            (artworkInfo.script.length === 0) :
            (localValueScript.length === 0))

        function makeUIElements(editableArtworkProperties: EditableArtworkProperty[]): any {
            if (!artworkInfo) {
                return <></>
            }
            return editableArtworkProperties.filter((dsp) => dsp.storageKey !== "script").map(dsp => {
                const label = <label>{dsp.description ?? dsp.storageKey}</label>
                const enabled = !isStoring && (userCanEdit || (dsp.storageKey === "archivist" && userIsAdmin));
                return (
                    <div className={dsp.storageKey} key={dsp.storageKey}>
                        {dsp.type !== "checkbox" && label}
                        {(dsp.type === "textarea") ? (
                            <textarea

                                disabled={!enabled}
                                rows={3}
                                placeholder={dsp.placeholderValue ?? dsp.storageKey}
                                defaultValue={getDisplayArtworkValueForKey(dsp.storageKey) ?? dsp.defaultValue}
                                onChange={e => valueChanged(dsp.storageKey, e.target.value)}
                            />
                        ) : (dsp.type === "checkbox") ? (
                            <>
                                <input
                                    disabled={!enabled}
                                    className={dsp.type}
                                    size={50}
                                    type={dsp.type}
                                    defaultChecked={localValues.get(dsp.storageKey) ?? (getDisplayArtworkValueForKey(dsp.storageKey) === "true")}
                                    onChange={e => valueChanged(dsp.storageKey, (e.target as any).checked)}
                                />
                                {label}
                            </>
                        ) : (dsp.type === "primary-royalties") ? (
                            <EditRoyalties storageKey={dsp.storageKey}
                                disabled={!enabled}
                                artwork={artworkInfo}
                                setLocalRoyalties={setPrimaryRoyalties}
                                royalties={primaryRoyalties}
                                preventDeletionOfFirst={true} />
                        ) : (dsp.type === "secondary-royalties") ? (
                            <EditRoyalties storageKey={dsp.storageKey}
                                disabled={!enabled}
                                artwork={artworkInfo}
                                setLocalRoyalties={setSecondaryRoyalties}
                                royalties={secondaryRoyalties} />
                        ) : (
                            <input
                                disabled={!enabled}
                                className={dsp.type}
                                size={50}
                                type={dsp.type}
                                placeholder={dsp.placeholderValue ?? dsp.storageKey}
                                defaultValue={getDisplayArtworkValueForKey(dsp.storageKey) ?? dsp.defaultValue}
                                onChange={e => valueChanged(dsp.storageKey, e.target.value)}
                            />
                        )}
                    </div>
                )
            })
        }

        body = (
            <div>
                <p>Artist Address: {artworkInfo.artist_address}</p>
                {viewArtworkLink()}
                <h3 id="basic-info">Information</h3>
                {makeUIElements(kEditableArtworkProperties.filter((p) => kInfoKeys.indexOf(p.storageKey) !== -1))}
                {storeButton()}
                <h3 id="basic-info">Pricing</h3>
                {makeUIElements(kEditableArtworkProperties.filter((p) => kPricingKeys.indexOf(p.storageKey) !== -1))}
                {storeButton()}

                <h3 id="artwork-script">Artwork source code</h3>
                <div className="script-editor" >
                    <div className="script-preview">
                        <button className="show-preview-button" onClick={e => { setArtworkScriptPreviewIsVisible(!artworkScriptPreviewIsVisible) }}>
                            {artworkScriptPreviewIsVisible ? "Hide" : "Show"} Preview
                        </button>
                        {artworkScriptPreviewIsVisible ? artworkScriptPreview(artworkScriptType, previewTokenSeed, previewMintNumber, previewScript) : (<></>)}
                    </div>
                    <label>Source code type</label>
                    <select
                        disabled={isStoring || !hasNoScript}
                        defaultValue={MintHtmlGenerator.getStringForScriptType(artworkScriptType)}
                        onChange={e => valueChanged("scriptTypeString", e.target.value)}>
                        {["p5.js v1.4.0", "three.js v0.135.0", "tone.js v14.7.77", "custom"].map((option) =>
                            <option key={option} value={option}>{option}</option>
                        )}
                    </select>
                    {!hasNoScript && <div className="no-change-scripttype-warning">Delete existing source code to change type</div>}
                    <p>If the version/library you want to use is not in this list, please contact us on our Discord. You can use "custom" and provide your own full html - just be sure you'll be able to maintain your script into the foreseeable future.</p>
                    <div className="artist-tools-github-link">
                        Take a look at <a href="https://github.com/Endless-Ways/artist-tools">https://github.com/Endless-Ways/artist-tools</a> for help figuring out what should go below.
                    </div>
                    <p>We recommend you include a license statement in your source code.</p>
                    {(artworkScriptType === ArtworkScriptType.CUSTOM) ? (
                        <div>
                            <label>The following javascript will be injected into your page at the symbol <code>!!!InjectEndlessWaysTokenInfoJSHere!!!</code></label>
                            <textarea className={"custom-token-sample"}
                                rows={4}
                                disabled={true}
                                value={MintHtmlGenerator.getTokenJS(artworkInfo.id, previewMintNumber, previewTokenSeed)}
                            />
                            <label>Custom page content</label>
                            <div className={"source-code"} >
                                <textarea disabled={isStoring || !userCanEdit}
                                    className={"editable"}
                                    rows={20}
                                    placeholder={MintHtmlGenerator.getPlaceholderSample(ArtworkScriptType.CUSTOM)}
                                    defaultValue={localValues.get("script") ?? artworkInfo?.script}
                                    onChange={e => valueChanged("script", e.target.value)}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <label>Artwork source code</label>
                            <div className={"source-code"} >
                                <textarea className={"token-sample"}
                                    disabled={true}
                                    value={`// information for this token, available to your code:
${MintHtmlGenerator.getTokenJS(artworkInfo.id,
                                        previewMintNumber,
                                        previewTokenSeed)}

// your artwork code appears below this line
// ------------------------------------------------------------`}
                                />
                                <textarea className={"editable"}
                                    disabled={isStoring || !userCanEdit}
                                    rows={20}
                                    placeholder={MintHtmlGenerator.getPlaceholderSample(artworkScriptType)}
                                    defaultValue={localValues.get("script") ?? artworkInfo?.script}
                                    onChange={e => valueChanged("script", e.target.value)}
                                />
                            </div>
                        </>
                    )}
                    {makeUIElements(kEditableArtworkProperties.filter((p) => kScriptExtraKeys.indexOf(p.storageKey) !== -1))}
                    {storeButton()}
                    <h3 id="basic-info">Miscelleneous</h3>
                    {makeUIElements(kEditableArtworkProperties.filter((p) => kMiscKeys.indexOf(p.storageKey) !== -1))}
                    {storeButton()}

                </div>
            </div>
        )
    }

    return (
        <div className="edit-artwork">
            <Prompt
                when={localValuesDifferFromStorage}
                message="There are unsaved changes, do you wish to discard them?"
            />
            <h2>Editing artwork with id {artworkId}</h2>
            {body}
            {rerollMint1Button()}
            <ClearImagesButton contractOracle={props.contractOracle} artworkId={artworkId} />
        </div>
    )
}

type ClearImagesButtonProps = {
    contractOracle: ContractOracle
    artworkId: number
}

const ClearImagesButton: FunctionComponent<ClearImagesButtonProps> = (props) => {

    const [isClearing, setIsClearing] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    function clearImages() {
        (async () => {
            setError(undefined);
            setIsClearing(true);
            const baseImagesUri = await props.contractOracle.getBaseImagesUri();
            const clearUrl = baseImagesUri + "6E187E50-9653-4C07-90B2-31957398F4D9/clearArtwork/" + props.artworkId.toString();
            //console.log("fetching " + clearUrl);
            try {
                await axios.get(clearUrl);
            } catch (error) {
                const errorObj = error as Error;
                if (errorObj) {
                    setError(errorObj.message);
                } else {
                    setError(JSON.stringify(error, Object.getOwnPropertyNames(error)));
                }
            } finally {
                //console.log("no longer clearing");
                setIsClearing(false);
            }
        })();
    }

    return (
        <div className="extra-button">
            <button
                className="button"
                disabled={isClearing}
                onClick={e => { clearImages() }}
            >
                {isClearing ? (
                    <span>
                        <i className="fas fa-spinner fa-spin"></i>&nbsp; clearing...
                    </span>
                ) : (
                    <span>
                        <i className="fa fa-soap"></i>&nbsp; clear image cache
                    </span>
                )}
            </button>
            {error && <div className="error">{error}</div>}
        </div>)
}



type EditRoyaltiesProps = {
    storageKey: string;
    artwork: ArtworkInfo;
    setLocalRoyalties: (newValue: any) => void;
    royalties?: RoyaltyInfo;
    preventDeletionOfFirst?: boolean;
    disabled: boolean;
}

const EditRoyalties: FunctionComponent<EditRoyaltiesProps> = (props) => {


    function addShare() {
        var newRoyalties = cloneDeep(props.royalties) ?? { decimals: new BigNumber(3), shares: [] };
        newRoyalties.shares.push({ "0": "", "1": new BigNumber(0) })
        //console.log("old royalties", props.royalties, "new royalties", newRoyalties);
        props.setLocalRoyalties(newRoyalties);
    }

    function removeShare(index: number) {
        var newRoyalties = cloneDeep(props.royalties);
        newRoyalties.shares.splice(index, 1);
        //console.log("old royalties", props.royalties, "new royalties", newRoyalties);
        props.setLocalRoyalties(newRoyalties);
    }

    function getDecimals(): number {
        return props.royalties?.decimals.toNumber() ?? 3
    }

    //console.log('Rendering royalties:', props.royalties);
    function getShareTotalUnits(): number {
        return Math.pow(10, getDecimals());
    }

    function getSharePercent(share: BigNumber): string {
        let fractionDigits = Math.max(0, getDecimals() - 2);
        const shareTimes100 = ((share.toNumber() / getShareTotalUnits()) * 100);
        if (fractionDigits > 0) {
            return shareTimes100.toFixed(fractionDigits);
        } else {
            return Math.floor(shareTimes100).toString();
        }
    }

    function setSharePercent(index: number, percent: number) {
        if (isNaN(percent)) {
            return;
        }
        let sharePct = Math.max(0, Math.min(100, percent)) / 100;
        let shareUnits = Math.round(getShareTotalUnits() * sharePct);
        var newRoyalties = cloneDeep(props.royalties);
        console.log("assigning share units", shareUnits, "(percent in", percent, "->", sharePct, "total", getShareTotalUnits(), "== 10 ^", props.royalties?.decimals.toNumber() ?? 3, ")");
        newRoyalties.shares[index]["1"] = new BigNumber(shareUnits);
        props.setLocalRoyalties(newRoyalties);
    }

    function setShareArtistAddress(index: number, address: string) {
        var newRoyalties = cloneDeep(props.royalties);
        newRoyalties.shares[index]["0"] = address;
        props.setLocalRoyalties(newRoyalties);
    }

    return (
        <div className="royalties">
            {props.royalties && props.royalties.shares.map((share, index) => {
                return (
                    <div className="share" key={props.storageKey + '-' + index}>
                        <label>Address</label>
                        <input
                            disabled={props.disabled}
                            className="address"
                            type="string"
                            defaultValue={share["0"]}
                            onChange={(e) => setShareArtistAddress(index, e.target.value)} />
                        <label>Share %</label>
                        <input
                            disabled={props.disabled}
                            className="share-amount"
                            type="number"
                            min={0}
                            max={100}
                            defaultValue={getSharePercent(share["1"])}
                            onChange={(e) => setSharePercent(index, parseFloat(e.target.value))}
                            onBlur={(e) => e.target.value = getSharePercent(share["1"])}
                        />
                        <button className="remove-button"
                            onClick={() => removeShare(index)}
                            disabled={props.disabled || (props.preventDeletionOfFirst && index === 0)}>
                            <i className="fas fa-trash"></i>
                        </button>
                    </div>
                )
            })}
            <button className="button"
                disabled={props.disabled}
                onClick={addShare}>
                <i className="fas fa-plus"></i>&nbsp; Add share
            </button>
        </div>
    )
}



export default EditArtwork;
